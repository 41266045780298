import React from "react";
import { Link } from "react-router-dom";
import imgbg from "../../assets/images/dot-map.svg";

const CommonPubadv = (props) => {
  return (
    <>
      <section className="slice pt-0 ">
        <div className="container">
          <div className="jumbotron rounded-diagonal-right bg-dark border-0 rounded-lg py-5 mt-5">
            <div className="card-body px-5">
              <img
                src={imgbg}
                alt="Image placeholder"
                className="img-fluid img-as-bg opacity-7"
              />
              <div className="row align-items-center">
                <div className="col-md-8">
                  <h4 className="text-white">{props.head}</h4>
                  <p className="text-white">
                    <strong className="text-white">Bidwave Media </strong>
                    {props.para}
                  </p>
                </div>
                <div className="col-12 col-md-4 text-right">
                  <Link to={props.btnlink} className="btn btn-black  btn-icon">
                    <span className="btn-inner--text">{props.btnname}</span>
                    <span className="btn-inner--icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-arrow-right"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonPubadv;
