import React, { Component, Suspense } from "react";

// Import Assets

import headImg from "../../assets/images/advertiser.png";
import AdvertiserComponent from "./AdvertiserComponent";
import { Helmet } from "react-helmet";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));

class AdvertiserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/services",
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
      <Helmet>
        <title>Unlock Powerful Digital Advertising Strategies to Drive Brand Growth, Customer Engagement, and Business Success.
</title>
          <meta name="description" content="Discover Powerful Advertiser Solutions with Bidwave Media. Drive Brand Growth, Customer Engagement, and Business Success through Effective Digital Advertising." />
  <meta name="keywords" content="advertiser solutions, digital advertising, brand growth, customer engagement " />
  </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <Banner
            titlePart1="You have found the best ad        "
            titlePart2="network for "
            titlePart3="advertisers."
            headImg={headImg}
            desc="Connect with your ideal customers through our highly
              personalized  marketing services."
          />
          <AdvertiserComponent />

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default AdvertiserPage;
