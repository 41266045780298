import React, { Component, Suspense } from "react";
import { Helmet } from "react-helmet";

// Import Assets

import headImg from "../../assets/images/blogs.png";
import BlogBox from "./BlogBox";
import Blogdatacmp from "./Blogdatacmp";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/blog",
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
      <Helmet>
      <title>Stay Updated with Expert Articles on Digital Marketing Trends, Strategies, and Industry Best Practices - Bidwave Media
</title>
          <meta name="description" content="Stay Informed with Bidwave Media's Marketing Insights Blog. Explore Expert Articles on Digital Trends, Strategies, and Industry Best Practices." />
  <meta name="keywords" content="digital marketing trends, strategies, industry best practices, marketing insights, digital marketing blogs, " />
  </Helmet>

        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <Banner
            titlePart1="Digital Marketing  "
            titlePart2="Agency Blogs"
            titlePart3="Bidwave Media."
            headImg={headImg}
            desc="Digital Marketing Blogs"
          />
          <div className="container mt-5 mb-5">
            <div className="row">
              {Blogdatacmp.map((val, ind) => {
                return (
                  <BlogBox link={val.link} img={val.img} title={val.title} />
                );
              })}
            </div>
          </div>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default BlogPage;
