import React, { Component, Suspense } from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";

import headImg from "../../assets/images/services/socialmedia/social-banner.png";
import feature1 from "../../assets/images/services/socialmedia/social-media.png";
import feature2 from "../../assets/images/services/socialmedia/social-content-creation.png";
import feature3 from "../../assets/images/services/socialmedia/social-audience.png";
import feature4 from "../../assets/images/services/socialmedia/social-paid-advertising.png";
import feature5 from "../../assets/images/services/socialmedia/socail-analytics.png";
import feature6 from "../../assets/images/services/socialmedia/social-online-presence.png";
import aboutImg from "../../assets/images/services/socialmedia/about-img.webp";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));
const Feature = React.lazy(() => import("./services/Feature.component"));

class SocialMediaMarketingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/services",
      features: [
        {
          key: 1,
          img: feature1,
          heading: "Social Media",

          desc: `Bidwave Media's Social Media Service is a dynamic and versatile solution designed to elevate businesses' online presence and engagement across a multitude of platforms. 
          With an in-depth understanding of the ever-evolving social media landscape, Bidwave Media offers a wide array of services aimed at empowering brands to connect with their target audience effectively.
          One of the Strategic Planning is foundational pillars of Bidwave Media's Social Media Service is strategic planning. They invest significant effort in developing customized social media strategies that are intricately aligned with your brand's specific goals. 
          This involves a thorough analysis of your target audience, industry trends, and competitive landscape. By identifying the right platforms and content approaches, Bidwave Media ensures that every post and interaction is geared towards maximizing your brand's impact on social media.
          `,
          align: "left",
        },
        {
          key: 2,
          img: feature2,
          heading: "Content Creation",

          desc: `In the fast-paced world of social media, content is king. Bidwave Media excels in crafting compelling and visually appealing social media content. 
          Whether it's eye-catching graphics, engaging videos, or persuasive copy, their content is tailored to each platform's unique nuances. This meticulous attention to detail ensures that your brand's messaging resonates effectively with the diverse audiences found across various social networks.
          `,
          align: "right",
        },
        {
          key: 3,
          img: feature3,
          heading: "Audience Engagement",

          desc: `Bidwave Media recognizes the importance of building a community around your brand. They engage with your audience through timely responses, fostering a sense of trust and belonging. 
          By actively participating in conversations and addressing queries or concerns, Bidwave Media helps your brand establish a genuine and lasting connection with its audience.

          `,
          align: "left",
        },
        {
          key: 4,
          img: feature4,
          heading: "Paid Advertising",

          desc: `Social media advertising is a powerful tool, and Bidwave Media leverages it to boost your brand's visibility. They create and manage targeted social media ad campaigns that drive traffic, increase conversions, and boost sales. 
          Whether it's promoting a new product, service, or simply expanding your reach, Bidwave Media's paid advertising efforts are meticulously designed to deliver results.
          `,
          align: "right",
        },
        {
          key: 5,
          img: feature5,
          heading: "Analytics and Optimization",

          desc: `In the world of social media, data is key. Bidwave Media continuously monitors the performance of your social media campaigns. They utilize data-driven insights to refine their strategies and achieve measurable results. 
          By analyzing metrics like engagement rates, click-through rates, and conversions, they ensure that your brand's social media efforts remain highly effective.
          `,
          align: "left",
        },
        {
          key: 6,
          img: feature6,

          desc: `By opting for Bidwave Media's Social Media Service, businesses can fortify their online presence, foster brand loyalty, and fully harness the immense potential of social media as a growth-driving platform. 
          In an age where social media is often the first point of contact between brands and their customers, Bidwave Media's expertise ensures that your brand stands out, engages effectively, and thrives in the digital landscape.
          For more detailed information and to explore the benefits of Bidwave Media's Social Media Service, you can visit their official website at Bidwave.Media.
          
          `,
          align: "right",
        },
      ],
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
      <Helmet>
      <title>Amplify Your Brand's Online Presence with Effective Social Media Strategies, Content Creation, and Audience Engagement - Bidwave Media
</title>
          <meta name="description" content="Boost Your Online Presence with Bidwave Media's Social Media Marketing Solutions. Harness the Power of Social Platforms, Content, and Audience Engagement." />
  <meta name="keywords" content="social media strategies, content creation, audience engagement, online presence, social media marketing agency, " />
  </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <Banner
            titlePart1="Supercharge Your"
            titlePart2="Social Media"
            titlePart3="Presence Today"
            headImg={headImg}
            desc="Elevate your social media presence with our expert strategies. We offer tailored solutions to boost your online visibility, engage your audience, and drive results. From compelling content creation to data-driven insights, we've got you covered. Don't miss out on the opportunity to shine in the digital world. Start supercharging your social media presence today and watch your brand soar to new heights. Success is just a click away!            "
          />

          <section className="section pt-3" id="social-media-features">
            <Container>
              {this.state.features.map((feature, key) => (
                <Feature details={feature} />
              ))}
            </Container>
          </section>

          <section className="section pt-3" id="social-media-about">
            <Container>
              <Row className="justify-content-center">
                <Col lg={12}>
                  <div className="title text-center mb-5">
                    <h3 className="font-weight-normal text-dark">
                      How Does Social Media
                      <span className="text-danger"> Marketing Work</span>
                    </h3>
                    <p className="text-muted">
                      Social media marketing is the process of using social
                      media platforms like Facebook, Twitter, Instagram,
                      LinkedIn, and others to promote a product or service. The
                      process typically involves creating content that is
                      engaging, relevant, and shareable and then publishing it
                      on social media platforms.
                    </p>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="about-img">
                    <img
                      src={aboutImg}
                      alt=""
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default SocialMediaMarketingPage;
