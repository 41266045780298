import React, { Component, Suspense } from "react";
import img from "../../assets/images/blog/traditionalmarketingvsdigitalmarketing.webp";
const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));

class Traditionalmarketingvsdigitalmarketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/blog",
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <div className="container mt-5 mb-5 pt-5">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="blog-details">
                  <img src={img} className="img-fluid" />
                  <h2 className="font-weight-normal text-dark mt-3 mb-3">
                    Traditional Marketing Vs Digital Marketing
                  </h2>
                  <h6>We are heading towards a perpetual Digital Marathon!</h6>

                  <p>
                    Gone are those days of marketing through good old newspapers
                    and texting through fax machines. With ever-growing
                    population & the advent of new technologies, we are entwined
                    digitally! Pragmatically, transitions are taking place in
                    many facets of the digital world. From magazines to banking
                    online and reading e-books, all and sundry it becomes more
                    imperative to go digital! So, is traditional marketing
                    withering out completely? How is digital marketing thriving
                    ahead making lives lucidly simple?
                  </p>
                  <h6>Traditional Marketing Vs Digital Marketing</h6>
                  <h6>Facets of Static Traditional Marketing:</h6>
                  <p>
                    Primarily, Traditional marketing is all about reaching out
                    to a local audience even though it’s not limited to one.
                    Like they say old habits die hard. People are so accustomed
                    to traditional marketing because of its longevity. Reading
                    billboards at long awaiting signals, skimming through
                    newspaper ads to find a groom or bride of their match &
                    picking up magazines at salons are few activities people
                    invariably do all the time. Tangible things like business
                    cards, print ads in newspapers and magazines, commercials on
                    TV and Radio, billboards and brochures have become the
                    facets of traditional marketing. This static technique
                    cannot be easily measured and it’s plummeting down in recent
                    times due to its exorbitant cost over varied fields.
                    Henceforth, cost-effective digital marketing has paved its
                    way to make life simpler and quicker like never before!
                  </p>
                  <h6>Dynamic Digital all the way! :</h6>
                  <p>
                    One of the major benefits or outcomes in digital marketing
                    is that it’s measurable! As the internet world continues to
                    evolve shoulder to shoulder with technological advances, it
                    becomes easy to reach out to a whole slew of people through
                    digital campaigns. You may consider it as a marketing coup,
                    but its global reach through the web as significantly
                    changed the lifestyle & moreover established an interactive
                    approach with the audience through various social outlets.
                  </p>
                  <p>
                    Digital marketing in many ways is similar to traditional
                    marketing, but it uses digital devices. YouTube videos,
                    banner ads, websites, content-oriented blogs exemplify
                    digital marketing. However, it’s a inbound marketing
                    strategy & its primary goal is for people to find you. The
                    best way to emphasize digital marketing is by quoting an
                    example: Say you are running a business and you put contents
                    or ads over the web for people to find. Using various social
                    networks or by conducting organic or paid search people
                    reach out to your business. The more the visitors, the more
                    trust they start to build with your brand and eventually you
                    start to build rapport through online.
                  </p>
                  <h6>Latest trends in Digital Marketing!</h6>
                  <p>
                    The enormity of reach digital medium provides in very less
                    time has made everyone go digital. Targeting the required
                    demographics and tracking them online & tweaking it
                    accordingly whenever necessary has proved time and again
                    that digital medium is the way ahead! As trends evolve each
                    and every year, marketers have to keep track of the changes
                    and emerging technologies to stay ahead in the Digital
                    World.
                  </p>
                  <p>
                    To keep you more digitally inclined and help you gain a
                    competitive edge over others, here is a glimpse of some of
                    the latest trends that are buzzing in the market:
                  </p>
                  <p>
                    <strong>1 Voice Search:</strong>It comes in 2 variants.
                    Those implemented on smart phones, & desktops such as Google
                    Assistant and Siri which gives written search results and
                    those implemented by smart speakers such as Google home,
                    Amazon’s Alexa which gives searches immediate answers to
                    their queries. According to an analysis done by ComScore by
                    2020 more than 50% results will be through voice search.
                  </p>
                  <p>
                    <strong>2 Live videos:</strong> It offers real time user
                    engagement generating greater impressions than any posts
                    published in the news feed. Live streaming is very effective
                    thanks to Facebook, YouTube & Instagram. The live content
                    allows marketers to engage directly with the customers by
                    improving their relationship with followers, reach a wider
                    audience & boost their traffic on the social medium
                    significantly.
                  </p>
                  <p>
                    <strong>3 Engagement based Email Marketing:</strong> Emails
                    are getting smarter day by day as marketers are juggling to
                    identify the behavior of their leads. To make sure the right
                    content reaches the right customer, certain tools and
                    applications are used to track the customer’s behavior.
                    Behavior-based Emails are sent to subscribers who are active
                    on the list with respect to the segmentation of data. Quite
                    lately, more personalized approach is considered in sending
                    engagement based emails. To avoid spams, plain text emails
                    are used which has improved the engagement rate.
                  </p>
                  <p>
                    As technology advances, still many trends are emerging such
                    as Bulk SMS marketing, affiliate marketing, mobile
                    advertising & social media marketing every year and
                    marketers have to make intelligent decisions to keep track
                    of their ongoing campaigns and inculcate the latest trends
                    to practice to stay ahead in the vying Digital world.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Traditionalmarketingvsdigitalmarketing;
