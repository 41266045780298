import React from "react";

const Advpubsbucomponents = (props) => {
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h2 className="font-weight-normal text-center text-dark mb-3">
              {props.head}
            </h2>
            <p className="text-muted text-center">{props.para}</p>
          </div>
          <div className="col-md-12 mt-4">
            <h4 className="text-dark text-center font-weight-normal mb-5 pt-3">
              {props.sbhead}
              <span className="text-danger"> {props.sbspan}</span>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advpubsbucomponents;
