import React, { Component, Suspense } from "react";
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";

import SectionTitle from "../common/SectionTitle.component";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));

const ContactInfo = React.lazy(() => import("./contact/ContactInfo.component"));
const ContactForm = React.lazy(() => import("./contact/ContactForm.component"));

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/contact-us",
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
      <Helmet>
        <title>Contact Us for Expert Digital Marketing Services, Consultations, and Collaborations to Boost Your Business.
</title>
          <meta name="description" content="Connect with Bidwave Media for Expert Digital Marketing Services and Collaborations. Reach Out to Boost Your Business with Our Consultations." />
  <meta name="keywords" content="contact us, digital marketing services, consultations, collaborations, " />
  </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />

          <section className="section mt-5" id="contact">
            <Container>
              <SectionTitle
                title1="Join Our "
                title2="Network"
                desc="Join our network of industry professionals and stay connected with the latest digital marketing updates and insights."
              />

              <Row>
                <Col lg={4}>
                  <div className="contact-address">
                    <h4 className="text-dark mb-4">Contact Info</h4>
                    <ContactInfo
                      location="Bidwave Digital Media LLC"
                      address="No.MF-18, AL Sayegh Building,Port Saeed, Dubai"
                      phone="+971502359697"
                    />
                  </div>
                </Col>

                <Col lg={{ size: 7, offset: 1 }}>
                  <ContactForm />
                </Col>
              </Row>
            </Container>
          </section>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default ContactPage;
