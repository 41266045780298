import React, { Component, Suspense } from "react";
import { Container } from "reactstrap";
import CommonPubadv from "./CommonPubadv";
import { Helmet } from "react-helmet";


// Import Assets
import feature1 from "../../assets/images/services/email/feature1-img.webp";
import feature2 from "../../assets/images/services/email/feature2-img.webp";
import headImg from "../../assets/images/services/email/head-img.webp";
import img1 from "../../assets/images/reporting.png";
import img2 from "../../assets/images/execution.png";
import img3 from "../../assets/images/understanding.png";
import About from "./home/About.component";
import AboutParaComponents from "./AboutParaComponents";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));
const Feature = React.lazy(() => import("./services/Feature.component"));

class AboutUsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/services",
      features: [
        {
          key: 1,
          img: "/assets/male.png",
          heading: "Reach the right audience at the right time",
          subHeading: "Smart Segmenter",
          desc: "Email marketing is one of the top marketing channels because of its stability, control, and affordability. Send relevant communication, personalize every email using the user’s details, behavior, and preferences, and reach your potential customers.",
          keypoints: [
            {
              title: "Demographic segmentation",
              desc: "Split your email list for new and existing customers to send out relevant communication. Demographic segmentation of emails increases click-through rates, open rates, and sales.",
            },
            {
              title: "Customer's attributes",
              desc: "Leverage the potential of personalized emails using the right customer data.",
            },
            {
              title: "Email types",
              desc: "Transactional, Marketing, and newsletter.",
            },
            {
              title: "Customer's engagement",
              desc: "Keep your customers engaged using their activity and time subscribed to build long-term relationships.",
            },
          ],
          align: "left",
        },
        {
          key: 2,
          img: feature2,
          heading: "Deliverability",
          subHeading: "Send Savvy",
          desc: "Deliverability tracks how many of your customers have received the emails. Successful email deliverability means that your emails reach your customer's inbox’s as intended. To ensure that your messages are delivered to your customers. You need to,",
          keypoints: [
            {
              title: "Clean Your Lists",
              desc: "Limit your subscribers, meaning remove subscribers who are no longer interested before they unsubscribe from your email. The cleaner the list, the higher the number of subscribers who wants to open it.",
            },
            {
              title: "Monitor Your Reputation",
              desc: "Make sure there are as few spam complaints as possible. Spam complaints are more likely to affect your ESP reputation, and also deliverability rate will suffer.",
            },
            {
              title: "Outbound Speed",
              desc: "Reach a wider audience to increase brand awareness and gain potential customers.",
            },
            {
              title: "Email Analysis",
              desc: "Analyze your email marketing campaigns and customer interaction, behavior, and weak points of your content. This will help you to work on the necessary aspects and improve your marketing campaigns.",
            },
          ],
          align: "right",
        },
      ],
    };
  }

  Loader = () => {
    return (
      <>
       
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
      </>
    );
  };

  render() {
    return (
      <React.Fragment>
         <Helmet>
          <title>Explore Our Expertise in Delivering Effective Advertising Solutions for Your Brand's Success
</title>
          <meta name="description" content="Discover the journey and expertise behind Bidwave Media. Learn how our digital advertising solutions can elevate your brand's success.
" />
  <meta name="keywords" content="digital advertising, marketing expertise, Bidwave Media, brand success, advertising solutions
" />

        </Helmet>

        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} navClass="nav-sticky" />
       
          {/*<Banner
            titlePart1="Reach wider audiences with our top-notch"
            titlePart2=" marketing "
            titlePart3="  solutions."
            headImg={headImg}
            desc="Connect with your ideal customers through our highly
              personalized email marketing services."
    />*/}
          <section className="about-banner">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <h1>About Us</h1>
                  <p>
                    Start working with Bidwave Media that can provide everything
                    you need to generate awareness, drive traffic, connect.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <AboutParaComponents />
          <About />

          <section className=" mt-5 pt-3 pb-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-6">
                  <div className="section-title text-center mb-4 pb-2 ">
                    <h6 className="text-danger">Work Process</h6>
                    <h4 className="title mb-4">How do we works ?</h4>
                    <p className="text-muted para-desc mx-auto mb-0">
                      we follow a systematic approach to deliver effective
                      digital marketing solutions that drive growth for your
                      business. Our process involves:
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 ">
                  <div className="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center pt-5">
                    <div className="icons text-center mx-auto">
                      <img src={img1} className="img-fluid" />
                    </div>

                    <div className="card-body">
                      <h5 className="text-dark">Understanding</h5>
                      <p className="text-muted mb-0">
                        We begin by getting to know your business, target
                        audience, and goals. Through discussion and analysis, we
                        gain insights into your unique needs and challenges.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card features feature-primary feature-clean work-process bg-transparent process-arrow border-0 text-center pt-5">
                    <div className="icons text-center mx-auto">
                      <img src={img2} className="img-fluid" />
                    </div>

                    <div className="card-body">
                      <h5 className="text-dark">Strategy &amp; Execution</h5>
                      <p className="text-muted mb-0">
                        Based on our understanding, we develop a customized
                        digital marketing strategy that aligns with your
                        business goals. We use a data-driven approach to testing
                        and refining the strategy to achieve optimal results.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div className="card features feature-primary feature-clean work-process bg-transparent d-none-arrow border-0 text-center pt-5">
                    <div className="icons text-center mx-auto">
                      <img src={img3} className="img-fluid" />
                    </div>

                    <div className="card-body">
                      <h5 className="text-dark">Measuring &amp; Reporting</h5>
                      <p className="text-muted mb-0">
                        We constantly monitor and measure the performance of the
                        strategy. We provide regular reports and updates to keep
                        you informed on the progress and adjust the strategy as
                        necessary to maximize the results.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <CommonPubadv
            head="Want us to manage your campaigns?"
            para="Become a publisher with us and turn challenges into opportunities. Get access to a world of possibilities and maximize your potential in the digital landscape today!            "
            btnname="Join Us Publiser"
            btnlink="/publisher"
          />

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default AboutUsPage;
