import img from "../../assets/images/tps.svg";
import img1 from "../../assets/images/mpo.svg";
import img2 from "../../assets/images/assured.svg";
import img3 from "../../assets/images/latestTech.svg";
import img4 from "../../assets/images/freeConslt.svg";

const Publisherdatacomponentns = [
  {
    img: img,
    title: "Timely Payment Schedule",
  },
  {
    img: img1,
    title: "Multiple Payment Options",
  },
  {
    img: img2,
    title: "Quality Assured Display Ad Campaigns",
  },
  {
    img: img3,
    title: "Use of Latest Technology",
  },
  {
    img: img4,
    title: "24x7 Free  Consultations",
  },
];
export default Publisherdatacomponentns;
