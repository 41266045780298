import React from "react";

import Advpubsbucomponents from "./Advpubsbucomponents";
import Advertiserdatacomponents from "./Advertiserdatacomponents";
import Advpubimgcomponents from "./Advpubimgcomponents";

const AdvertiserComponent = () => {
  return (
    <div className="adv-sec mt-5 mb-5">
      <Advpubsbucomponents
        head="ADVERTISER"
        para="Harness the power of our network to broadcast your advertisements and reap impressive returns on investment. We offer you a versatile platform to showcase your products and generate income. Leveraging our multi-channel ad delivery networks, we elevate your business, enabling your products to reach a broader audience and achieve optimal conversion rates."
        sbhead="Our Modes "
        sbspan="of Campaign"
      />
      <div className="container">
        <div className="row justify-content-center">
          {Advertiserdatacomponents.map((val, ind) => {
            return <Advpubimgcomponents img={val.img} title={val.title} />;
          })}
        </div>
        <div className="col-md-12 text-center mt-5 mb-5">
          <a class="btn btn-danger" href="/contact-us">
            Join Us As Advertiser <span class="ml-2 right-icon">→</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AdvertiserComponent;
