import React, { Component, Suspense } from "react";
import { Container } from "reactstrap";

import SectionTitle from "../common/SectionTitle.component";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const ParagraphSection = React.lazy(() =>
  import("../common/ParagraphSection.component")
);

class TermsAndConditionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/legal",
      paragraphs: [
        {
          heading: "Terms & Conditions   ",
          desc: "If you want to get an Account with Bidwave Digital Media, you have to clear the Registration process. Both Advertisers and Publishers who wish to get an account with the company have to clear the registration process. The registration process must be cleared perfectly by providing accurate info like your Email Id, your unique password and username. After filling up of the registration form, we’ll scrutinize it and then approve it. When you are registering with Bidwave Digital Media you naturally agree upon the following.",
        },
        {
          desc: " Your age is beyond 18 years or at least you have reached 18. The company has all right to terminate your registration in case of any misconduct on your part .       The company also reserves total discretion to reject the application you dropped for registration or even cancel your registration without prior notice.          The company keeps updating its Terms & Conditions from time to time without any prior notice to its client base. You should bear the responsibility to keep yourself informed about the new Terms & Conditions coming up on our official website and abide by those.",
        },
        {
          heading: "Your Conduct          ",
          desc: "Bidwave Digital Media account holders should note that their account with us is non-transferable, non-resalable and non-assignable in nature. So any such act is strictly prohibited.          You shall not use our official company website for any unlawful or unethical purposes.          It is your sole responsibility to take care of your account. You are supposed to keep your Bidwave Digital Media User ID and password confidential and never ever share it with any third party. If you do so, it will drill holes in our security system and prove a threat for the company and its clients in the long run. If you ever suspect any sort of unauthorized usage of your existing Bidwave Digital Media account, notify us immediately. It will help us to take quick action before a major breach of or break in the security system occurs.          You must support and cooperate with Bidwave Digital Media when we need to access the account you have with us, for various reasons. We are in no way answerable to you. However to maintain trust and transparency in relations we inform you that the company needs to access account only for security-related issues, support or maintenance purposes.          You shall not commit any sort of cyber offences like implanting dangerous viruses into our company website that may harm our website. ",
        },
        {
          heading: "Disclosure",
          desc: " Our Company uses multiple online channels for expanding the consumer base of the clients. Online communication network is what we harness for this purpose. Some of the sophisticated modes that we put to use are CPL or Cost Per Lead, CPC or Cost Per Click, CPA or Cost Per Action and the list goes on.",
        },
        {
          heading: "Liabilities",
          desc: " The company does not take the responsibility for any damage or loss that you encounter by choosing our services. To be more specific, we do not claim to be responsible if your revenues, your goodwill, your profits are not reaching the peaks but are lost after choosing our services.",
        },
      ],
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />

          <section className="section" id="privacy-policy">
            <Container>
              <SectionTitle title1="Terms and " title2="Conditions" />

              {this.state.paragraphs.map((paragraph, key) => (
                <ParagraphSection details={paragraph} />
              ))}
            </Container>
          </section>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default TermsAndConditionsPage;
