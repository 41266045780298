import React, { Component, Suspense } from "react";
import { Helmet } from "react-helmet";

// Import Assets

import headImg from "../../assets/images/publisher.png";

import PublisherComponent from "./PublisherComponents";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));

class PublisherPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/publisher",
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
      <Helmet>
      <title>Explore Lucrative Opportunities for Publishers to Monetize Their Online Assets and Maximize Revenue Potential.
</title>
          <meta name="description" content="Unlock Lucrative Opportunities as a Publisher with Bidwave Media. Monetize Your Online Assets and Maximize Revenue Potential through Our Innovative Solutions." />
  <meta name="keywords" content="publisher opportunities, monetize online assets, maximize revenue potential." />
  </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <Banner
            titlePart1="You have found the best ad "
            titlePart2="network for "
            titlePart3="publishers."
            headImg={headImg}
            desc="Connect with your ideal customers through our highly
              personalized  marketing services."
          />
          <PublisherComponent />
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default PublisherPage;
