import React from "react";
import abtimg from "../../assets/images/about-img.png";

const AboutParaComponents = () => {
  return (
    <div className="pt-5 pb-5 mb-5 mt-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img src={abtimg} alt="about image" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <div className="abt-para">
              <h2>Who we are ?</h2>
              <p>
                Bidwave stands as a dynamic digital marketing agency with a
                steadfast dedication to providing exceptional solutions that
                yield concrete outcomes for businesses. Our cadre of experts
                possesses the requisite experience and proficiency to assist you
                in constructing a robust online footprint, cultivating leads,
                and fostering the growth of your enterprise. At Bidwave Media,
                our unwavering commitment revolves around delivering customized
                and data-centric strategies that align with your distinctive
                requisites and aspirations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutParaComponents;
