import blogimg1 from "../../assets/images/blog/catapultyourbrandsmarketingroithroughaffiliatemarketing.webp";
import blogimg2 from "../../assets/images/blog/contentmarketingstrategiestogrowyourbusiness.webp";
import blogimg3 from "../../assets/images/blog/howtogetstartedwithperformancemarketing.webp";
import blogimg4 from "../../assets/images/blog/shorthandsmsmarketingstillhasitsleadoverlargemarginsglobally.webp";
import blogimg5 from "../../assets/images/blog/traditionalmarketingvsdigitalmarketing.webp";
import blogimg6 from "../../assets/images/blog/canyoumaximizeyourreachandsalesthroughvideomarketing.png";
import blogimg7 from "../../assets/images/blog/customerexperience-themostimportantmarketingtool.png";
import blogimg8 from "../../assets/images/blog/emailmarketingtrendstoexpectthisyearandbeyond.png";
import blogimg9 from "../../assets/images/blog/googleadsvsfacebookadsunfoldingthedichotomy.png";
import blogimg10 from "../../assets/images/blog/howaiischangingthelandscapeofdigitalmarketing.png";
import blogimg11 from "../../assets/images/blog/whatmistakesshouldyouavoidwhileadvertisingonfacebook.png";
import { NavLink } from "react-router-dom";

const Blogdatacmp = [
  {
    link: "/traditional-marketing-vs-digital-marketing",
    img: blogimg1,
    title: "Traditional Marketing Vs Digital Marketing ",
  },
  {
    link: "/how-to-get-started-with-performance-marketing",
    img: blogimg2,
    title: "How to get started with Performance Marketing ",
  },
  {
    link: "/content-marketing-strategies-to-grow-your-business",
    img: blogimg3,
    title: "Content Marketing strategies to grow your business ",
  },
  {
    link: "/short-hand-sms-marketing-still-has-its-lead-over-large-margins-globally",
    img: blogimg4,
    title:
      "Short hand SMS marketing still has its lead over large margins globally  ",
  },
  {
    link: "/catapult-your-brands-marketing-roi-through-affiliate-marketing",
    img: blogimg5,
    title: "Catapult your Brands marketing ROI through Affiliate Marketing  ",
  },
  {
    link: "/can-you-maximize-your-reach-and-sales-through-video-marketing",
    img: blogimg6,
    title: "Can you maximize your reach and sales through video marketing  ",
  },
  {
    link: "/how-ai-is-changing-the-landscape-of-digital-marketing",
    img: blogimg7,
    title: "How AI is changing the landscape of Digital Marketing  ",
  },
  {
    link: "/google-ads-vs-facebook-ads-unfolding-the-dichotomy",
    img: blogimg8,
    title: "Google Ads Vs Facebook Ads Unfolding the dichotomy  ",
  },
  {
    link: "/email-marketing-trends-to-expect-this-year-and-beyond",
    img: blogimg9,
    title: "Email marketing trends to expect this year and beyond  ",
  },
  {
    link: "/what-mistakes-should-you-avoid-while-advertising-on-facebook",
    img: blogimg10,
    title: "What mistakes should you avoid while advertising on Facebook  ",
  },
  {
    link: "/customer-experience-the-most-important-marketing-tool",
    img: blogimg11,
    title: "Customer Experience-The Most Important Marketing Tool ",
  },
];

export default Blogdatacmp;
