import React, { Component, Suspense } from "react";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

import feature1 from "../../assets/images/services/search/search-advertising.png";
import feature2 from "../../assets/images/services/search/sa-keyword-research.png";
import feature3 from "../../assets/images/services/search/sa-campaign-creation.png";
import feature4 from "../../assets/images/services/search/sa-ad-optimization.png";
import feature5 from "../../assets/images/services/search/sa-budget-management.png";
import feature6 from "../../assets/images/services/search/sa-performance-tracking.png";
import feature7 from "../../assets/images/services/search/sa-online-visibility.png";
import headImg from "../../assets/images/services/search/sa-main-banner.png";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));
const Feature = React.lazy(() => import("./services/Feature.component"));

class SearchAdvertisingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/services",
      features: [
        {
          key: 1,
          img: feature1,
          heading: "Search Advertising          ",

          desc: "Bidwave Media's Search Advertising Service is a highly effective and meticulously crafted solution that empowers businesses to enhance their online visibility and attract targeted traffic to their websites. With a steadfast commitment to maximizing return on investment (ROI) and delivering tangible, measurable results, Bidwave Media excels in several key areas:",
          align: "right",
        },
        {
          key: 2,
          img: feature2,
          heading: "Keyword Research",

          desc: "The foundation of successful search advertising lies in keyword research, and Bidwave Media takes this task seriously. They conduct comprehensive keyword research to identify the most pertinent and high-converting keywords specific to your industry and target audience. This diligent process ensures that your advertising efforts are optimized for relevance and effectiveness.          ",
          align: "left",
        },
        {
          key: 3,
          img: feature3,
          heading: "Campaign Creation",

          desc: `Bidwave Media's expertise extends to the design and implementation of strategic pay-per-click (PPC) advertising campaigns. These campaigns are strategically placed on prominent search engines such as Google and Bing. 
          The goal is simple: to ensure that your ads are prominently displayed when potential customers search for products or services related to your business. This strategic placement enhances your brand's visibility and increases the likelihood of attracting qualified leads.
          `,
          align: "right",
        },
        {
          key: 4,
          img: feature4,
          heading: "Ad Copy Optimization",

          desc: ` compelling ad copy is an art form, and Bidwave Media excels in this arena. They create ad copy that not only captures users' attention but also encourages click-throughs. 
          Furthermore, their ad copy optimization techniques are designed to improve your quality score. A higher quality score not only reduces ad costs but also enhances the overall efficiency of your advertising efforts.
          `,
          align: "left",
        },
        {
          key: 5,
          img: feature5,
          heading: "Budget Management",

          desc: "Managing your advertising budget is a critical aspect of successful search advertising, and Bidwave Media does it with precision. They carefully allocate resources to the most profitable keywords and strategically optimize bids to maximize efficiency. This ensures that your advertising budget is used judiciously and generates the best possible results. ",
          align: "right",
        },
        {
          key: 6,
          img: feature6,
          heading: "Performance Tracking",

          desc: `In the digital realm, data is king, and Bidwave Media provides detailed analytics and performance reports. These insights allow you to closely monitor the success of your advertising campaigns. 
          Metrics such as click-through rates, conversion rates, and cost per acquisition are meticulously tracked. Armed with this data, you can make data-driven adjustments to your campaigns, ensuring continuous improvement and enhanced results over time.
          `,
          align: "left",
        },
        {
          key: 7,
          img: feature7,

          desc: `By selecting Bidwave Media's Search Advertising Service, businesses can unlock the potential for greater online visibility. This service not only attracts highly qualified leads but also increases the likelihood of conversions. Ultimately, it contributes to a stronger bottom line, solidifying your business's digital presence and success.
          Therefore, Bidwave Media's commitment to excellence in keyword research, campaign creation, ad copy optimization, budget management, and performance tracking makes their Search Advertising Service an invaluable asset for businesses looking to thrive in the competitive online landscape. 
          For more comprehensive information and to explore the benefits of Bidwave Media's Search Advertising Service, you can visit their official website at Bidwave.Media`,
          align: "right",
        },
      ],
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
      <Helmet>
        <title>Maximize Your Online Visibility and ROI with Targeted Search Ads, Keyword Optimization, and Performance Tracking - Bidwave Media
</title>
          <meta name="description" content="Maximize ROI with Bidwave Media's Search Advertising Services. Enhance Online Visibility, Optimize Keywords, and Track Performance for Business Success." />
  <meta name="keywords" content="search ads, keyword optimization, online visibility, performance tracking, search advertising agency, " />
  </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <Banner
            titlePart1="Maximize Visibility with Targeted"
            titlePart2="Search"
            titlePart3="Campaigns."
            headImg={headImg}
            desc="Harness the power of targeted search campaigns to amplify your online visibility. Our strategic approach ensures your brand reaches the right audience, driving quality traffic and conversions. By optimizing keywords and leveraging data-driven insights, we guarantee maximum impact. Stay ahead of the competition and dominate search engine results. Let us catapult your business to the forefront of online success. Seize the opportunity now and watch your visibility soar to unprecedented levels. Elevate your brand with precision and expertise!            "
          />

          <section className="section pt-3" id="features">
            <Container>
              {this.state.features.map((feature, key) => (
                <Feature details={feature} />
              ))}
            </Container>
          </section>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default SearchAdvertisingPage;
