import React from "react";

const Advpubimgcomponents = (props) => {
  return (
    <>
      <div className="col-md-2 col-sm-6 col-6">
        <div className="adv-ser">
          <img src={props.img} alt="img1" className="img-fluid" />
          <p>{props.title}</p>
        </div>
      </div>
    </>
  );
};

export default Advpubimgcomponents;
