import img from "../../assets/images/cpm.svg";
import img1 from "../../assets/images/cpc.svg";
import img2 from "../../assets/images/cpv.svg";
import img3 from "../../assets/images/cpa.svg";
import img4 from "../../assets/images/cpi.svg";

const Advertiserdatacomponents = [
  {
    img: img4,
    title: "Cost Per Install (CPI)",
  },
  {
    img: img,
    title: "Cost Per Mille    (CPM)",
  },
  {
    img: img1,
    title: "Cost Per Click    (CPC)",
  },
  {
    img: img2,
    title: "Cost Per Visit    (CPV)",
  },
  {
    img: img3,
    title: "Cost Per Acquisition    (CPA)",
  },
];
export default Advertiserdatacomponents;
