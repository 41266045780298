import React, { Component, Suspense } from "react";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

import SectionTitle from "../common/SectionTitle.component";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const ParagraphSection = React.lazy(() => import("../common/ParagraphSection.component"));

class PrivacyPolicyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/legal",
      paragraphs: [
        {
          heading: "PRIVACY POLICY",
          desc: "Bidwave Digital Media values the privacy of its users. Reading the following will come handy for understanding Bidwave Digital Media Privacy Policy. The personal information submitted by the users are highly valuable to Bidwave Digital Media. For better understanding and comprehension of the information, Bidwave Digital Media has links to various other foreign websites. The information pertaining to Privacy Policy and other information displayed on this website does not hold good for other websites. In case, there arises any need for you to know about a piece of information relevant to their website, contacting them directly is suggested.",
        },
        {
          heading: "NEED FOR PRIVACY POLICY",
          desc: "We aspire to follow the guidelines while we procure your personal information. By using this website and by making use of any of our services, you agree to allow us to have your personal information. We request all our esteemed users to read carefully this Privacy Policy, so that in case of any doubts and dispute the information of the Privacy Policy helps.",
        },
        {
          heading: "GATHERING AND USE OF PERSONAL INFORMATION",
          desc: "Bidwave Digital Media collects and uses only those relevant information which are submitted by the users by their own choice. This contains only personal information of the users such as their name, age, email id etc. Bidwave Digital Media and all its institutions will make use of the information only when there is a request of information on behalf of the user.\n\n The main objective is to make better our services for our users. Thus, in order to contact with you this piece of information is vital. Without your knowledge, Bidwave Digital Media will never leak out any information regarding you.",
        },
        {
          heading: "NEED AND USE OF USERS’ NON PERSONAL INFORMATION",
          desc: "In order to judge and compare the performance of our own website regarding quality and services, several survey programmes are conducted by us like many other such organisations. During such times, we do collect certain information about our users which do not directly come under the purview of personal information. This kind of information is regarding the user’s internet connection, or the use of any particular browser, or the kinds of pages viewed and accessed by the users. This is known as Non Personal Information, and the identity of the users do not at all gets revealed by collecting and using this information. It solely directs towards improvising the website and its services.",
        },
        {
          heading: "COOKIES: PURPOSE AND USE",
          desc: "The files that analyse the web traffic are known as cookies. They are sent to the user’s web browser and are subsequently stored on the hard drive of the user’s computer. It is prime to mention here that Bidwave Digital Media does not make use of these cookies. It doesn’t send these files to its users’ browsers.\n\n Bidwave Digital Media however uses web beacons which are graphic image, transparent in nature that are usually placed on websites or emails in order to track down and record user’s actions. This enables us to determine users’ activities and their browsing nature while they are logged on to our website. In the process there could be advertisements displayed.",
        },
        {
          heading: "NOT FOR UNDER 13",
          desc: "Bidwave Digital Media doesn’t entertain or allow the use of its website by children who are below 13 years of age. Every user ought to submit his /her personal information before accessing to the website. In case, when a child below 13 years wants to know about certain information, s/he must do so under the guidance and aid of a parent or guardian.",
        },
        {
          heading: "HOW SAFE IS YOUR PERSONAL INFORMATION",
          desc: "Well, Bidwave Digital Media has implemented all types of technological measures and techniques to keep up the safe, security and privacy of your personal data, so that it does not land on the hands of miscreants and unauthorised people. Our users are also authorised to access their respective personal information data on paying a meagre amount of money. They can also request correction of any piece of information which they deem as incorrect or inappropriate. This is done absolutely free of any cost.",
        },        
      ]
    };
  }

  Loader = () => {
    return (
      <div id='preloader'>
        <div id='status'>
          <div className='spinner'>
            <div className='dot1'></div>
            <div className='dot2'></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
            <Helmet>
      <title>Your Privacy Matters to Us. Learn How We Safeguard Your Data and Information in Our Digital Marketing Services.
</title>
          <meta name="description" content="Your Privacy Matters to Us. Explore Bidwave Media's Privacy Policy and Discover How We Safeguard Your Data in Our Digital Marketing Services." />
  <meta name="keywords" content="privacy policy, data protection, digital marketing services" />
  </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />

          <section className='section' id='privacy-policy'>
            <Container>
              <SectionTitle
                title1='Privacy '
                title2='Policy'
              />

              {this.state.paragraphs.map((paragraph, key) => (
                <ParagraphSection details={paragraph} />
              ))}
            </Container>
          </section>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default PrivacyPolicyPage;
