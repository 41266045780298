import React from "react";
import { Link } from "react-router-dom";

const BlogBox = (props) => {
  console.log(props);
  return (
    <>
      <div className="col-md-4 mt-2 mb-1">
        <div className="blog-box">
          <Link to={props.link}>
            <img src={props.img} alt="main" className="img-fluid" />
            <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">
              {props.title}
            </h5>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BlogBox;
