import React, { Component, Suspense } from "react";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

// Import Assets
import feature1 from "../../assets/images/services/email/email-marketing.png";
import feature2 from "../../assets/images/services/email/strategic-campaign-planning.png";
import feature3 from "../../assets/images/services/email/creative-content.png";
import feature4 from "../../assets/images/services/email/list-management.png";
import feature5 from "../../assets/images/services/email/performance-optimization.png";
import feature6 from "../../assets/images/services/email/automation.png";
import feature7 from "../../assets/images/services/email/em-compliance.png";
import feature8 from "../../assets/images/services/email/email-marketing-service.png";
import headImg from "../../assets/images/services/email/em-main-Banner.png";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));
const Feature = React.lazy(() => import("./services/Feature.component"));

class EmailMarketingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/services",
      features: [
        {
          key: 1,
          img: feature1,
          heading: "Email Marketing",

          desc: `Bidwave Media offers a comprehensive Email Marketing Service that serves as a potent tool for businesses to establish, nurture, and ultimately convert their target audience effectively. 
          At the heart of Bidwave Media's approach lies a commitment to delivering results-driven campaigns, and their expertise shines through in several key areas:  `,

          align: "left",
        },
        {
          key: 2,
          img: feature2,
          heading: "Strategic Campaign Planning",

          desc: ` Bidwave Media's Email Marketing Service begins with a deep dive into your business objectives and audience preferences. Through close collaboration with clients, they craft tailored email marketing strategies. 
          These strategies are designed to align seamlessly with your goals, ensuring that every email campaign serves a distinct purpose and engages your audience in a meaningful way.
          `,

          align: "right",
        },
        {
          key: 3,
          img: feature3,
          heading: "Creative Content",

          desc: `The art of creating compelling email content is at the core of Bidwave Media's offering. They excel at designing visually captivating emails that leave a lasting impression. 
          Their persuasive copy, enticing graphics, and clear calls to action are meticulously crafted to not only captivate readers but also drive high conversion rates.
          `,

          align: "left",
        },
        {
          key: 4,
          img: feature4,
          heading: "List Management",
          desc: `Building and maintaining a robust email subscriber list is fundamental to successful email marketing. Bidwave Media takes charge of list management, assisting clients in growing their lists organically. 
          Moreover, they employ advanced segmentation techniques to ensure personalized communication. Importantly, they also ensure strict compliance with email marketing regulations such as CAN-SPAM and GDPR to protect your sender reputation and maintain trust with subscribers.

          `,

          align: "right",
        },
        {
          key: 5,
          img: feature5,
          heading: "Performance Optimization",
          desc: `Bidwave Media's commitment to excellence is evident in their continuous efforts to optimize email campaign performance. 
          They employ A/B testing methodologies to rigorously experiment with different elements of email campaigns, from subject lines to visuals and content. 
          These data-driven insights allow them to make informed adjustments, with the ultimate goal of maximizing open rates, click-through rates, and, most importantly, the return on investment (ROI).
          `,

          align: "left",
        },
        {
          key: 6,
          img: feature6,
          heading: "Automation",
          desc: `Bidwave Media leverages the power of automation tools to streamline email marketing processes. 
          By sending targeted and time-sensitive emails based on subscriber behavior, they ensure that your audience receives the right messages at precisely the right moments, enhancing engagement and conversion opportunities.
          `,

          align: "right",
        },
        {
          key: 7,
          img: feature7,
          heading: "Compliance",
          desc: ` In the ever-evolving landscape of email marketing regulations, Bidwave Media ensures full compliance with standards like CAN-SPAM and GDPR.
          This commitment to compliance safeguards your brand against legal risks and fosters trust among your subscribers.
          
          `,

          align: "left",
        },
        {
          key: 8,
          img: feature8,
          heading: "Bidwave Media's Email Marketing Service",
          desc: ` Bidwave Media's Email Marketing Service isn't just about sending emails; it's a holistic approach designed to help businesses tap into the full potential of email marketing. 
          It's a cost-effective and results-oriented marketing channel that drives engagement and, ultimately, revenue growth.
          Therefore, Bidwave Media's dedication to strategic planning, creative content, list management, performance optimization, automation, and compliance collectively make their Email Marketing Service a valuable asset for businesses looking to thrive in the digital landscape. For more in-depth information, you can visit their official website at Bidwave.Media
          
          `,

          align: "right",
        },
      ],
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
      <Helmet>
        <title>Drive Engagement and Conversions with Expertly Crafted Email Campaigns, Analytics, and Audience Segmentation - Bidwave Media
</title>
          <meta name="description" content="Experience Effective Email Marketing with Bidwave Media. Craft Engaging Campaigns, Analyze Results, and Engage Audiences for Enhanced Business Growth." />
  <meta name="keywords" content="email campaigns, engagement, conversions, analytics, audience segmentation, email marketing agency, " />
  </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <Banner
            titlePart1="Revamp your marketing strategy with targeted"
            titlePart2="email"
            titlePart3="campaigns."
            headImg={headImg}
            desc="Connect with your ideal customers through our highly
              personalized email marketing services."
          />

          <section className="section pt-3" id="features">
            <Container>
              {this.state.features.map((feature, key) => (
                <Feature details={feature} />
              ))}
            </Container>
          </section>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default EmailMarketingPage;
