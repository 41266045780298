import React, { Component, Suspense } from "react";
import img from "../../assets/images/blog/googleadsvsfacebookadsunfoldingthedichotomy.png";
import img1 from "../../assets/images/blog/google-ads.jpg";
import img2 from "../../assets/images/blog/facebook-ads.jpg";
const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));

class Googleadsvsfacebookadsunfoldingthedichotomy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/blog",
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <div className="container mt-5 mb-5 pt-5">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="blog-details">
                  <img src={img} className="img-fluid" />
                  <h2 className="font-weight-normal text-dark mt-3 mb-3">
                    Google Ads Vs Facebook Ads Unfolding The Dichotomy
                  </h2>
                  <p>
                    Ever since digital marketing has spread its tentacles
                    globally, people have found various ways to do businesses
                    online through Google & Facebook. It comes to solid
                    understanding that both Google & facebook have been the
                    thresholds for people looking for advertising, to achieve
                    maximum visibility, to generate leads & new customers.
                    Without further due let’s try to dissect the both with
                    respect to marketing & selling.
                  </p>
                  <h6>Short Term Google Ads For Sales:</h6>
                  <img src={img1} className="img-fluid" />
                  <p>
                    Google is a perfect fit for people who are looking for
                    intent based marketing. It helps prospective buyers to find
                    your business. It’s more like setting a trap for your
                    customers by invariably generating leads. Moreover, it’s all
                    about sales. Businesses sell their product & services
                    through Google to people who are looking for specific items.
                  </p>
                  <p>
                    Primarily, Google search engine works on SEO. It’s all about
                    the volume & range of the popular keyword listings. Ad sense
                    is run on the websites if the intent of buying is huge for
                    products and services. Before running Google Ads, certain
                    sections have to be taken in to consideration- Keywords,
                    demographics, location, negative keywords, device &
                    language.
                  </p>

                  <p>
                    Say for example you are searching for “hotels near me” in
                    Google. It will list all the hotels with respect to
                    location. The search results will have “Ad”, “sponsored”
                    listing & also the most relevant results based on my
                    location.
                  </p>

                  <p>
                    It’s an expensive proposition none the less. An avg CPC for
                    Google ads is somewhere between 40 cents- 5$.
                  </p>

                  <p>
                    Ideally Google is used for B2B prospects. It has a global
                    market share of 80% & a whopping 2.4 million searches per
                    minute.
                  </p>
                  <p>
                    One drawback is that by running Google ads you might come
                    across unqualified leads.
                  </p>
                  <h6>Long term Facebook Ads for Marketing:</h6>
                  <img src={img2} className="img-fluid" />
                  <p>
                    Facebook, on the other hand, is for targeting a broad
                    audience. For example, you can target people who are
                    interested in bikes, beauty or any other market out there.
                  </p>
                  <h6>Increases Return on Investment (ROI):</h6>
                  <p>
                    Facebook helps businesses find prospective customers. It’s
                    more like you are going to hunt for customers. Never treat
                    Face book like Google. Here you can just look for marketing
                    your product or business page but can’t sell.
                  </p>
                  <p>
                    It’s more of broadcasting medium. Here you announce out loud
                    and clear where people can get to know about you & your
                    product. For example, if you are planning to sell a low-end
                    product on Face book you can direct people to your product
                    landing page but you can’t convert them to buy your product
                    unless you educate them about your product first.
                  </p>

                  <p>
                    Unlike Google, Facebook has got the ability to target
                    people. It has a huge collection of data. With 2 billion
                    active users and 90% social network traffic, people can find
                    a whole lot of information based on interest, location,
                    behaviors, demographics, lookalikes, & website visitors.
                  </p>

                  <p>
                    As per stats & figures, it’s proficiently inexpensive
                    compared to Google. The avg CPC is under a dollar. With
                    mindboggling methodologies to target people at the granule
                    level, you can market on Facebook without spending an
                    enormous amount of money.
                  </p>
                  <p>
                    Ideally, Face book is for B2C prospects. It’s mainly for
                    people who are looking for brand awareness.
                  </p>
                  <p>
                    Few drawbacks are there on Facebook too. As many people are
                    busy chatting with their friends & family, it’s difficult to
                    draw their attention towards your ads & it requires a lot of
                    time & resources in order to get followers & likes.
                  </p>
                  <p>
                    To conclude I feel you got to use both Facebook & Google in
                    order to carry out your business online. Without marketing,
                    you can’t sell. Take your time & invest wisely!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Googleadsvsfacebookadsunfoldingthedichotomy;
