import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import SectionTitle from "../../common/SectionTitle.component";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="about">
          <Container>
            <SectionTitle
              title1="About "
              title2="Us"
              desc="We recognize that the landscape of digital marketing is ever-evolving, and achieving success requires staying ahead of the curve."
            />

            <Row>
              <Col md={4}>
                <h4 className="text-dark font-weight-light f-36 mb-4">
                  Innovative Strategies for Digital Success
                </h4>
              </Col>
              <Col md={{ size: 7, offset: 1 }}>
                <Row>
                  <Col md={6}>
                    <h6 className="text-dark font-weight-light f-20 mb-3">
                      Our Mission
                    </h6>
                    <p className="text-muted font-weight-light">
                      Bidwave Media is a digital marketing agency that helps
                      brands connect with their target audiences and drive
                      growth through innovative and cutting-edge marketing
                      solutions.
                    </p>
                  </Col>
                  <Col md={6}>
                    <h6 className="text-dark font-weight-light f-20 mb-3">
                      Our Vision
                    </h6>
                    <p className="text-muted font-weight-light">
                      The vision of Bidwave Media is to be the most trusted
                      performance marketing partner for advertisers and agencies
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
