import React, { Component, Suspense } from "react";
import { Container, Row, Col } from "reactstrap";

import SectionTitle from "../common/SectionTitle.component";
import error from "../../assets/images/error.png";
import { Link } from "react-router-dom";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));

const ContactInfo = React.lazy(() => import("./contact/ContactInfo.component"));
const ContactForm = React.lazy(() => import("./contact/ContactForm.component"));

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/error-404",
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <div className="page-error text-center ">
            <img src={error} className="img-fluid" />
            <h2 className="mt-2 ">Page not found</h2>
            <h6>
              <Link to="/">Back to Homepage</Link>
            </h6>
          </div>
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default ContactPage;
