import React from "react";

import Advpubsbucomponents from "./Advpubsbucomponents";
import Advpubimgcomponents from "./Advpubimgcomponents";
import Publisherdatacomponentns from "./Publisherdatacomponents";

const PublisherComponent = () => {
  return (
    <>
      <div className="adv-sec mt-5 mb-5">
        <Advpubsbucomponents
          head="PUBLISHER"
          para="Guiding users to an advertising page featuring a multitude of products and services presents a formidable task, but we thrive on transforming these challenges into opportunities. Our platform empowers you to introduce end users to the advertiser's products and services. Simultaneously, we facilitate ad promotion, maintain the steady flow of traffic, and ensure you receive the commissions you rightfully deserve."
          sbhead="Following are the "
          sbspan="reasons to join us"
        />
        <div className="container">
          <div className="row justify-content-center">
            {Publisherdatacomponentns.map((val, ind) => {
              return <Advpubimgcomponents img={val.img} title={val.title} />;
            })}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mt-5 mb-5">
              <a class="btn btn-danger" href="/contact-us">
                Join Us As Publisher <span class="ml-2 right-icon">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublisherComponent;
